import Vue from 'vue'

export default {
  addFavouriteSpecialist ({userId, specialistId}) {
    return Vue.axios.post('/dashboard/specialists/favourite/add', {userId, specialistId})
  },
  removeFavouriteSpecialist({userId, specialistId}) {
    return Vue.axios.post('/dashboard/specialists/favourite/remove', {userId, specialistId})
  }
}
